import React from  'react';
import {toTigrinya} from "../../utils/toTigrinya";
import { withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import letters from "../../utils/tigrinyaAlphabets";

const styles = theme => ({
    alphabetHeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '60px',
    },
    bodies: {
        display: 'flex',
        flexDirection: 'column',

    },
    body: {
        maxWidth: "800px",
        marginBottom: '30px'
    },
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '30px'
    },
    twoAlphabets: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '30px',
        margin: '25px 0'
    },
    rootWords: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        fontSize: '30px',
        lineHeight: '70px',

    },
    group: {
        fontSize: '50px',
        marginBottom: '20px'
    },
    sentencesWithImage: {
        display: 'flex',


    },
    position: {
        display: 'flex',
        flexDirection: 'column',
        JustifyContent: 'flex-end',
        paddingTop: '20px',
    },
    sentences: {
        display: 'flex',
        flexDirection: 'column',
        width: '70%'
    },
    posters: {
        display: 'flex',
        flexDirection: 'column',
    }

});

class PracticeReading extends React.PureComponent {

    render() {
        const { classes, match } = this.props;

        console.log ("match ", match);

        const alphabet = Object.values(letters).filter(d => d.main && d.title === match.params.id)[0];

        return (
            <div className="alphabet">
                <div className={classes.alphabetHeader}>
                    {toTigrinya(match.params.id)}
                </div>
                <div className={classes.bodies}>
                    <div className={classes.body} style={{marginBottom: '100px'}}>
                        <span className={classes.group}>1</span>
                        <div className={classes.root}>
                            { alphabet.letter.map(a => {
                                return <div key={a}>{a.geez}</div>
                            })}

                        </div>
                        <div className={classes.twoAlphabets}>
                            { alphabet.easy_row_1.map(a => {
                                return <div key={a.id}>{a.value}</div>
                            })}

                        </div>
                        <div className={classes.twoAlphabets}>
                            { alphabet.easy_row_2.map(a => {
                                return <div key={a.id}>{a.value}</div>
                            })}
                        </div>
                    </div>
                    <div className={classes.sentencesWithImage}>
                        <div className={classes.sentences}>
                            <div className={classes.body}>
                                <span className={classes.group}>2</span>
                                <div className={classes.rootWords}>
                                    { alphabet.medium.map(a => {
                                        return <div key={a}>{a.value}</div>
                                    })}
                                </div>
                            </div>
                            <div className={classes.body}>
                                <span className={classes.group}>3</span>
                                <div className={classes.rootWords}>
                                    { alphabet.advanced.map(a => {
                                        return <div key={a}>{a.value}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={classes.posters}>
                            <div>
                                <img style={{width: '80%', height: '80%'}} src={alphabet.posters.first} alt="first poster"/>
                            </div>
                            <div>
                                <img style={{width: '80%', height: '80%'}} src={alphabet.posters.second} alt="second poster"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

PracticeReading.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
}
export default withRouter(withStyles(styles, {withTheme: true})(PracticeReading));
