import letterTypes from './letterTypes';
import ItemTypes from './ItemTypes';
import * as cartoons from "../resources/assets/images";

/**
 * List of tigrinaya alphabets.
 * key 'main' - boolean, indicates the parent alphabet key is not the primary english alphabet to point
 * to this tigrinya alphabet
 */
const tigrinyaAlphabets = {
    "h": {
        "letter": [
            { id: 1, lable:'\u1200', value: 'he', geez: 'ሀ', type: ItemTypes.HADE },
            { id: 2, lable:'\u1201', value: 'hu', geez: 'ሁ', type: ItemTypes.KILITE },
            { id: 3, lable:'\u1202', value: 'hi', geez: 'ሂ', type: ItemTypes.SELESTE},
            { id: 4, lable:'\u1203', value: 'ha', geez: 'ሃ',type: ItemTypes.ARBAETE},
            { id: 5, lable:'\u1204', value: 'hie',geez: 'ሄ', type: ItemTypes.HAMUSHTE},
            { id: 6, lable:'\u1205', value: 'h',  geez: 'ህ',type: ItemTypes.SHUDUSHTE},
            { id: 7, lable:'\u1206', value: 'ho', geez: 'ሆ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-h',
        "title": 'he',
        "path": '/reading/he',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel he ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'በርሀ'},
            {id: 2, value: 'ሁኩይ'},
            {id: 3, value: 'ሂባትኒ'},
            {id: 4, value: 'ሃገረሰብ'},
            {id: 5, value: 'ሄሊኮብተር'},
            {id: 6, value: 'ህበይ'},
            {id: 7, value: 'ሆየ-ሆየ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'በርሀ በሊዑ።'},
            {id: 2, value: 'ሁኩይ ተመሃራይ።' },
            {id: 3, value: 'ኣደይ ሂባትኒ።'},
            {id: 4, value: 'ሃገረ-ሰብ ከይደ።'},
            {id: 5, value: 'ሄልኮብተር በሪራ።'},
            {id: 6, value: 'ህበይ ዘሊላ።'},
            {id: 7, value: 'ሆየ-ሆየ ሒዘ።'}

        ],
        "advanced": [
            {id: 1, value: 'በርሀ ቅጫ በሊዑ።'},
            {id: 2, value: 'ሁኩይ ተመሃራይ ኣብ ክፍሊ ደቂሱ።'},
            {id: 3, value: 'ኣደይ ጥዓሞተይ ሂባትኒ።'},
            {id: 4, value: 'ሃገረሰብ ከይደ ፈቴዮ።'},
            {id: 5, value: 'ሄሊኮብተር ናብ ሰማይ በሪራ።'},
            {id: 6, value: 'ህበይ ባናና ትፈቱ።'},
            {id: 7, value: 'ሆየ-ሆየ ሒዘ ኣሳጊረዮም።' }
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 0,
    },
    "l": {
        "letter": [
            {id: 1, lable:'\u1208', value: 'le',geez: 'ለ', type: ItemTypes.HADE },
            {id: 2, lable:'\u1209', value: 'lu',geez: 'ሉ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u120A', value: 'li',geez: 'ሊ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u120B', value: 'la',geez: 'ላ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u120C', value: 'lie', geez: 'ሌ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u120D', value: 'l', geez: 'ል', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u120E', value: 'lo',geez: 'ሎ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-l',
        "title": 'le',
        "path": '/reading/le',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel le ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ለሚን'},
            {id: 2, value: 'ሉል' },
            {id: 3, value: 'ስእሊ'},
            {id: 4, value: 'ላም'},
            {id: 5, value: 'ሌካሌካ'},
            {id: 6, value: 'ልቢ'},
            {id: 7, value: 'ካውሎ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ለሚን በሊዐ ።'},
            {id: 2, value: 'ሉል ረኺበ ።' },
            {id: 3, value: 'ስእሊ ስኢሉ ።'},
            {id: 4, value: 'ላም በሊዓ ።'},
            {id: 5, value: 'ሌካሌካ ልሒሰ።'},
            {id: 6, value: 'ልቢ ስኢለ ።'},
            {id: 7, value: 'ካውሎ ገዚአ ።'}
        ],
        "advanced": [
            {id: 1, value: 'መጺጽ ለሚን በሊዐ ።'},
            {id: 2, value: 'ኣነ ሉል ረኺበ ።'},
            {id: 3, value: 'ህበይ ስእሊ ስኢሉ ።'},
            {id: 4, value: 'ብላዕሊ ሊላይ ነፊሩ ።'},
            {id: 5, value: 'ላም ሳዕሪ በሊዓ ።'},
            {id: 5, value: 'ሰራቒ ሌካሌካ ሰሪቑ ።'}
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 1,
    },
    "L": {
        "letter": [
            {id: 1, lable:'\u1208', type: ItemTypes.HADE },
            {id: 2, lable:'\u1209', type: ItemTypes.KILITE },
            {id: 3, lable:'\u120A', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u120B', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u120C', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u120D', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u120E', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-L',
        "title": 'L',
        "path": '/reading/Le',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Le ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "H": {
        "letter": [
            { id: 1, lable:'\u1210', value: 'He',geez: 'ሐ', type: ItemTypes.HADE },
            { id: 2, lable:'\u1211', value: 'Hu',geez: 'ሑ', type: ItemTypes.KILITE },
            { id: 3, lable:'\u1212', value: 'Hi',geez: 'ሒ', type: ItemTypes.SELESTE},
            { id: 4, lable:'\u1213', value: 'Ha',geez: 'ሓ', type: ItemTypes.ARBAETE},
            { id: 5, lable:'\u1214', value: 'Hie',geez: 'ሔ',type: ItemTypes.HAMUSHTE},
            { id: 6, lable:'\u1215', value: 'H', geez: 'ሕ', type: ItemTypes.SHUDUSHTE},
            { id: 7, lable:'\u1216', value: 'Ho',geez: 'ሖ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-H',
        "title": 'He',
        "path": '/reading/He',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel He ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ተመስሐ'},
            {id: 2, value: 'ፈሪሑ' },
            {id: 3, value: 'ስብሒ'},
            {id: 4, value: 'ሓቂ'},
            {id: 5, value: 'ሔቦ'},
            {id: 6, value: 'ፍልሖ'} ,
            {id: 7, value: 'ሕራይ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ንሱ ተመስሐ።'},
            {id: 2, value: 'ፈሪሑ ኣሎ።'},
            {id: 3, value: 'ስብሒ ኣብዚሑ።'},
            {id: 4, value: 'ሓቂ ተዛሪቡ።'},
            {id: 5, value: 'ሕራይ ኢልዋ።'},
            {id: 6, value: 'ፍልሖ ርእየ።'}
        ],
        "advanced": [
            {id: 1, value: 'ኣሮን ኣብ መውዓሊ ህጻናት ተመስሐ።'},
            {id: 2, value: 'ሄርሞን ሕማቕ ፊልም ስለዝረኣየ ፈሪሑ።'},
            {id: 3, value: 'ስብሒ ምብዛሕ ንጥዕና ጽቡቕ ኣይኮነን።'},
            {id: 4, value: 'ሓቂ ምዝራብ ጽቡቕ ልምዲ እዩ።'},
            {id: 5, value: 'ሕጻን ያፌት ኲሉ ግዜ ሕራይ ይብል።'},
            {id: 6, value: 'ኣብ ስታቫንገር ፍልሖ የለን።'}
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 2,
    },
    "m": {
        "letter":[
            {id: 1, lable:'\u1218', value: 'me', geez: 'መ', type: ItemTypes.HADE },
            {id: 2, lable:'\u1219', value: 'mu', geez: 'ሙ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u121A', value: 'mi', geez: 'ሚ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u121B', value: 'ma', geez: 'ማ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u121C', value: 'mie',geez: 'ሜ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u121D', value: 'm',  geez: 'ም', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u121E', value: 'mo', geez: 'ሞ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-m',
        "title": 'me',
        "path": '/reading/me',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel me ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'መስቀል'},
            {id: 2, value: 'ሙሉጌታ' },
            {id: 3, value: 'ሕልሚ'},
            {id: 4, value: 'ማማ'},
            {id: 5, value: 'ሜስ'},
            {id: 6, value: 'ምርቓ'},
            {id: 7, value: 'ሞሶብ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'መስቀል ኣሲራ።'},
            {id: 2, value: 'ሙሉጌታ መጺኡ።'},
            {id: 3, value: 'ሕልሚ ሓሊሙ።'},
            {id: 4, value: 'ሜስ የስክር`ዩ።'},
            {id: 5, value: 'ምርቓ ጽቡቕ`ዩ።'},
            {id: 6, value: 'ሞሶብ ኣለና።'}
        ],
        "advanced": [
            {id: 1, value: 'ራሄል ኣብ ክሳዳ መስቀል ኣሲራ።'},
            {id: 2, value: 'ሙሉጌታ ካብ ስራሕ መጺኡ።'},
            {id: 3, value: 'ሄኖክ ጽቡቕ ሕልሚ ሓሊሙ።'},
            {id: 4, value: 'ማማ ንገዛ ሕጂ መጺኣ።'},
            {id: 5, value: 'ሜስ ሙቁር መስተ እዩ።'},
            {id: 6, value: 'ምርቓ ወለዲ ንሕጻናት ጽቡቕ እዩ።'},
            {id: 7, value: 'ኣብ ገዛና ሞሶብ ኣለና።'}
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 3,
    },
    "M": {
        "letter":[
            {id: 1, lable:'\u1218', type: ItemTypes.HADE },
            {id: 2, lable:'\u1219', type: ItemTypes.KILITE },
            {id: 3, lable:'\u121A', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u121B', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u121C', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u121D', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u121E', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-M',
        "title": 'M',
        "path": '/reading/Me',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Me ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "r": {
        "letter":[
            {id: 1,lable:'\u1228', value: 're',geez: 'ረ', type: ItemTypes.HADE },
            {id: 2,lable:'\u1229', value: 'ru',geez: 'ሩ', type: ItemTypes.KILITE },
            {id: 3,lable:'\u122A', value: 'ri',geez: 'ሪ', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u122B', value: 'ra',geez: 'ራ', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u122C', value: 'rie',geez: 'ሬ', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u122D', value: 'r', geez: 'ር', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u122E', value: 'ro',geez: 'ሮ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-r',
        "title": 're',
        "path": '/reading/re',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel re ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ረጋቢት'},
            {id: 2, value: 'ሩባ' },
            {id: 3, value: 'ሪታ'},
            {id: 4, value: 'ራዛ'},
            {id: 5, value: 'ርግኦ'},
            {id: 6, value: 'ሮማ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ረጋቢት ይነፍራ።'},
            {id: 2, value: 'ራዛ ብልሓተኛ።'},
            {id: 3, value: 'ርግኦ በሊዐ።'},
            {id: 4, value: 'ሮማ ሽኮሪና።'},
            {id: 5, value: 'ሩባ ማይ።'},
            {id: 6, value: 'ሪታ ስማ።'}
        ],
        "advanced": [
            {id: 1, value: 'ረጋቢት ዝነፍራ የዕዋፍ እየን።'},
            {id: 2, value: 'ኣብ ሩባ ማይ ኣሎ።'},
            {id: 3, value: 'ሪታ ስማ ጓል ይፈልጥ።'},
            {id: 4, value: 'ራዛ ብልሓተኛ እዩ።'},
            {id: 5, value: 'ኣነ ርግኦ ምስ ገዓት በሊዐ።'},
            {id: 6, value: 'ሮማ ሽኮሪና ጋል እያ።'}
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 4,
    },
    "R": {
        "letter":[
            {id: 1,lable:'\u1228', type: ItemTypes.HADE },
            {id: 2,lable:'\u1229', type: ItemTypes.KILITE },
            {id: 3,lable:'\u122A', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u122B', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u122C', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u122D', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u122E', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-R',
        "title": 'R',
        "path": '/reading/Re',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel R ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "s": {
        "letter":
        [
            { id: 1, lable:'\u1230', value: 'se', geez: ' ሰ', type: ItemTypes.HADE },
            { id: 2, lable:'\u1231', value: 'su', geez: 'ሱ', type: ItemTypes.KILITE },
            { id: 3, lable:'\u1232', value: 'si', geez: 'ሲ', type: ItemTypes.SELESTE},
            { id: 4, lable:'\u1233', value: 'sa', geez: 'ሳ', type: ItemTypes.ARBAETE},
            { id: 5, lable:'\u1234', value: 'sie',geez: 'ሴ', type: ItemTypes.HAMUSHTE},
            { id: 6, lable:'\u1235', value: 's',  geez: 'ስ', type: ItemTypes.SHUDUSHTE},
            { id: 7, lable:'\u1236', value: 'so', geez: 'ሶ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-s',
        "title": 'se',
        "path": '/reading/se',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel se ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ሰሌዳ'},
            {id: 2, value: 'ሱሳ' },
            {id: 3, value: 'ሲራክ'},
            {id: 4, value: 'ሳሬት'},
            {id: 5, value: 'ሴማፎሮ'},
            {id: 6, value: 'ስጋ'},
            {id: 7, value: 'ሶኒ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ሰሌዳ ደምሲሰ።'},
            {id: 2, value: 'ሱሳ ዓመተይ።'},
            {id: 3, value: 'ሲራክ ሓሪቁ።'},
            {id: 4, value: 'ሳሬት ፈሪሐ።'},
            {id: 5, value: 'ስጋ በሊዐ።'},
            {id: 6, value: 'ሶኒ ሓሚመ።'}
        ],
        "advanced": [
            {id: 1, value: 'መምህረይ ኣብ ሰሌዳ ጽሒፉ።'},
            {id: 2, value: 'ኣባሓጎይ ሱሳ ዓመቱ።'},
            {id: 3, value: 'ሲራክ ዓቢ ሓወይ እዩ።'},
            {id: 4, value: 'ሳሬት ሸውዓተ እግሪ ኣለዎ።'},
            {id: 5, value: 'ሴማፎሮ ሰለስተ ዓይነት ሕብሪ ኣለዎ።'},
            {id: 6, value: 'ኣንበሳ ስጋ ይፈቱ።'},
            {id: 7, value: 'ሶኒ ደኪመ ደቂሰ ኣብ ትምህርቲ።'},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 5,
    },
    "S": {
        "letter":[
            {id: 1, lable:'\u1238', value: 'Se', geez: 'ሸ', type: ItemTypes.HADE },
            {id: 2, lable:'\u1239', value: 'Su', geez: 'ሹ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u123A', value: 'Si', geez: 'ሺ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u123B', value: 'Sa', geez: 'ሻ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u123C', value: 'Sie',geez: 'ሼ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u123D', value: 'S',  geez: 'ሽ', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u123E', value: 'So', geez: 'ሾ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-S',
        "title": 'Se',
        "path": '/reading/Se',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Se ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ሸበጥ'},
            {id: 2, value: 'ሹዱሽተ' },
            {id: 3, value: 'ሻሂ'},
            {id: 4, value: 'ሽምዓ'},
            {id: 5, value: 'ሾሞንተ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ሽበጥ ለቢሰ።'},
            {id: 2, value: 'ሹዱሽተ ኣባጊዕ።'},
            {id: 3, value: 'ሻሂ ይፈቱ ።'},
            {id: 4, value: 'ሽምዓ መኺዂ ።'},
            {id: 5, value: 'ሾሞንተ ካራሜላ።'},
        ],
        "advanced": [
            {id: 1, value: 'ሸበጥ ኣብ መውዓሊ ህጻናት ይለብስ።'},
            {id: 2, value: 'ሽዱሽተ ዓመተይ ትምህርቲ ጀሚረ።'},
            {id: 3, value: 'ባንን ሻሂን ቆሪሰ።'},
            {id: 4, value: 'ልቺ ጠፊኣ ሽምዓ ወሊዕና።'},
            {id: 5, value: 'ኣብ ሾሞንተ ዓመተይ ሳልሳይ ክፍሊ ጀሚረ።'},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 6,
    },
    "q": {
        "letter":[
            {id: 1,lable:'\u1240', value: 'qe', geez: 'ቀ', type: ItemTypes.HADE },
            {id: 2,lable:'\u1241', value: 'qu', geez: 'ቁ', type: ItemTypes.KILITE },
            {id: 3,lable:'\u1242', value: 'qi', geez: 'ቂ', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1243', value: 'qa', geez: 'ቃ', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1244', value: 'qie',geez: 'ቄ', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1245', value: 'q',  geez: 'ቅ', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1246', value: 'qo', geez: 'ቆ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-q',
        "title": 'qe',
        "path": '/reading/qe',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel qe ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ቀጠልያ'},
            {id: 2, value: 'ቁማል' },
            {id: 3, value: 'ዑንቂ'},
            {id: 4, value: 'ቃንጥሻ'},

        ],
        "easy_row_2": [
            {id: 5, value: 'ቄራና- መንገዲ'},
            {id: 6, value: 'ቅርኒብ- ዓይኒ'},
            {id: 7, value: 'ቆርበት'}
        ],
        "medium": [
            {id: 1, value: 'ቀጠልያ ሕብሪ።'},
            {id: 2, value: 'ቁማል ርእየ።'},
            {id: 3, value: 'ዑንቂ ረኪበ።'},
            {id: 4, value: 'ቃንጥሻ በሊዐ።'},
            {id: 5, value: 'ቅርኒብ ዓይኒ ኣለኒ።'},
            {id: 6, value: 'ቆርበት በጊዕ።'}
        ],
        "advanced": [
            {id: 1, value: 'ኣነ ቀጠልያ ሕብሪ ስረ ኣሎኒ።'},
            {id: 2, value: 'ቁማል ደቂቅ ፍጥረት እዩ።'},
            {id: 3, value: 'ዑንቂ ገይረ ናይ ክሳድ ሰሪሐ።'},
            {id: 4, value: 'ቃንጥሻ ጥዑም እዩ።'},
            {id: 5, value: 'ቅርኒብ ዓይኒ ኣብ ትሕቲ ሸፋሽፍቲ ዓይንና ይርከብ።'},
            {id: 6, value: 'ኣነ ሕብሪ ቆርበተይ ይፈትዎ።'}
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 7,
    },
    "Q": {
        "letter":[
            {id: 1,lable:'\u1250', value: 'Qe', geez: 'ቐ', type: ItemTypes.HADE },
            {id: 2,lable:'\u1251', value: 'Qu', geez: 'ቑ', type: ItemTypes.KILITE },
            {id: 3,lable:'\u1252', value: 'Qi', geez: 'ቒ', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1253', value: 'Qa', geez: 'ቛ', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1254', value: 'Qie',geez: 'ቘ', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1255', value: 'Q',  geez: 'ቕ', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1256', value: 'Qo', geez: 'ቖ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-Q',
        "title": 'Qe',
        "path": '/reading/Qe',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Qe ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ቐድሐ'},
            {id: 2, value: 'ቐሺ' },
            {id: 3, value: 'ቛንቛ'},
            {id: 4, value: 'ምብራቕ'},
            {id: 5, value: 'ሕቖ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ማይ ቐድሐ።'},
            {id: 2, value: 'ቐሺ ተሳሊመ።'},
            {id: 3, value: 'ቛንቛ ትግርና።'},
            {id: 4, value: 'ምብራቕ ጸሓይ።'},
            {id: 5, value: 'ሕቖይ ሓሚመ።'}
        ],
        "advanced": [
            {id: 1, value: 'ንሱ ማይ ቐድሐ።'},
            {id: 2, value: 'ኣቦይ ቐሺ መስቀል ኣሳሊምና።'},
            {id: 3, value: 'ኣነ ቛንቛ ትግርኛ ይዛረብ።'},
            {id: 4, value: 'ጸሓይ ብምብራቕ በሪቃ።'},
            {id: 5, value: 'ከኒና በሊዐ ሕቖይ ሓውየ ።'}
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 8,
    },
    "b": {
        "letter":[
            {id: 1,lable:'\u1260', value: 'be', geez: 'በ', type: ItemTypes.HADE },
            {id: 2,lable:'\u1261', value: 'bu', geez: 'ቡ', type: ItemTypes.KILITE },
            {id: 3,lable:'\u1262', value: 'bi', geez: 'ቢ', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1263', value: 'ba', geez: ' ባ', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1264', value: 'bie',geez: 'ቤ', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1265', value: 'b',  geez: 'ብ', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1266', value: 'bo', geez: ' ቦ ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-b',
        "title": 'be',
        "path": '/reading/be',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel be ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'በለስ'},
            {id: 2, value: 'ቡንባ' },
            {id: 3, value: 'ቢንቶ'},
            {id: 4, value: 'ባቡር'},
            {id: 5, value: 'ቤትሮ'},
            {id: 6, value: 'ብናዊ'},
            {id: 7, value: 'ቦርሳ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'በለስ በሊዐ።'},
            {id: 2, value: 'ቡንባ ተበላሽያ።'},
            {id: 3, value: 'ቢንቶ ርእየ።'},
            {id: 4, value: 'ባቡር ተሰቂለ።'},
            {id: 5, value: 'ቤትሮ ሰይረ።'},
            {id: 6, value: 'ቦርሳ ተሓንጊጠ።'}
        ],
        "advanced": [
            {id: 1, value: 'በለስ ፍሩታ እዩ።'},
            {id: 2, value: 'ቢንቶ ጸልማት እዩ።'},
            {id: 3, value: 'ኣርማድዮና ቤትሮ ኣለዎ።'},
            {id: 4, value: 'ባቡር ፈጣን መጋዓዝያ እያ።'},
            {id: 5, value: 'ዝፈትዎ ሕብሪ ብናዊ እዩ።'},
            {id: 6, value: 'ትምህርቲ ጀሚረ ቦርሳ ገዚአ።'}
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 9,
    },
    "B": {
        "letter":[
            {id: 1,lable:'\u1260', type: ItemTypes.HADE },
            {id: 2,lable:'\u1261', type: ItemTypes.KILITE },
            {id: 3,lable:'\u1262', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1263', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1264', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1265', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1266', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-B',
        "title": 'Be',
        "path": '/reading/Be',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Be ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "v": {
        "letter":[
            {id: 1, lable:'\u1268', value: 've', geez: 'ቨ', type: ItemTypes.HADE },
            {id: 2, lable:'\u1269', value: 'vu', geez: 'ቩ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u126A', value: 'vi', geez: 'ቪ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u126B', value: 'va', geez: 'ቫ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u126C', value: 'vie',geez: 'ቬ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u126D', value: 'v',  geez: 'ቭ', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u126E', value: 'vo', geez: 'ቮ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-v',
        "title": 've',
        "path": '/reading/ve',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel ve ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ፌቨን'},
            {id: 2, value: 'ቪላ' },
            {id: 3, value: 'ቫዝሊን'},
            {id: 4, value: 'ቬሎ'},
            {id: 5, value: 'ቮሊቮል'},
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ፌቨን ከይዳ።'},
            {id: 2, value: 'ቪላጆ ይነብር።'},
            {id: 3, value: 'ቪድዮ መሊአ።'},
            {id: 4, value: 'ቪላ ኣለዎ።'},
            {id: 5, value: 'ቫዘሊን ተለክየ።'},
            {id: 6, value: 'ቬሎ ተቀዲዱ።'},
            {id: 7, value: 'ቮሊቮል ተጻዊታ።'}
        ],
        "advanced": [
            {id: 1, value: 'ፌቨን ናብ ዓዲ ከይዳ።'},
            {id: 2, value: 'ሙሴ ኣብ ቪላጆ ይነብር።'},
            {id: 3, value: 'ናይ ልደት ቪድዮ መሊአ።'},
            {id: 4, value: 'ሳራ ቮሊቮል ተጻዊታ።'},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 10,
    },
    "V": {
        "letter":[
            {id: 1, lable:'\u1268', type: ItemTypes.HADE },
            {id: 2, lable:'\u1269', type: ItemTypes.KILITE },
            {id: 3, lable:'\u126A', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u126B', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u126C', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u126D', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u126E', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-V',
        "title": 'Ve',
        "path":'/reading/Ve',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Ve ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "t": {
        "letter":[
            {id: 1,lable:'\u1270', value: 'te', geez: 'ተ', type: ItemTypes.HADE },
            {id: 2,lable:'\u1271', value: 'tu', geez: 'ቱ', type: ItemTypes.KILITE },
            {id: 3,lable:'\u1272', value: 'ti', geez: 'ቲ', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1273', value: 'ta', geez: 'ታ', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1274', value: 'tie',geez: 'ቴ', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1275', value: 't',  geez: 'ት', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1276', value: 'to', geez: 'ቶ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-t',
        "title": 'te',
        "path": '/reading/te',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel te ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ተመን'},
            {id: 2, value: 'ቱታ' },
            {id: 3, value: 'ቲንታ'},
            {id: 4, value: 'ታኒካ'},
            {id: 5, value: 'ቴሌፎን'},
            {id: 6, value: 'ትምህርቲ'},
            {id: 7, value: 'ቶርታ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ተመን ነኪሱኒ።'},
            {id: 2, value: 'ቱታ ተከዲነ።'},
            {id: 3, value: 'ቲንታ ተጽይቀ።'},
            {id: 4, value: 'ታኒካ ሓጺን እዩ።'},
            {id: 5, value: 'ቴሌፎን ደዊለ።'},
            {id: 6, value: 'ትምህርቲ ከይደ።'},
            {id: 7, value: 'ቶርታ በሊዐ።'}
        ],
        "advanced": [
            {id: 1, value: 'ተመን መርዛም እዩ።'},
            {id: 2, value: 'ላዕልን ታሕትን ቱታ ተከዲነ።'},
            {id: 3, value: 'ማማ ቲንታ ጾጉራ ገይራ።'},
            {id: 4, value: 'ብዙሕ ታኒካ ኣኪበ።'},
            {id: 5, value: 'ቴሌፎን ደውለ ዓባየይ ረኪበያ።'},
            {id: 6, value: 'መርዓ ከይደ ቶርታ ተዋሂበ።'}
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 11,
    },
    "c": {
        "letter":[
            {id: 1, lable:'\u1278', value: 'ce', geez: 'ቸ', type: ItemTypes.HADE },
            {id: 2, lable:'\u1279', value: 'cu', geez: 'ቹ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u127A', value: 'ci', geez: 'ቺ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u127B', value: 'ca', geez: 'ቻ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u127C', value: 'cie',geez: 'ቼ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u127D', value: 'c',  geez: 'ች', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u127E', value: 'co', geez: 'ቾ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-c',
        "title": 'ce',
        "path": '/reading/ce',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel ce ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ቸዝ'},
            {id: 2, value: 'ቺታ' },
            {id: 3, value: 'ቻው'},
            {id: 4, value: 'ችኮላታ'},
            {id: 5, value: 'ኣቾቶ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ቸዝ ተጻዊተ።'},
            {id: 2, value: 'ቺታ ኣይረኣኩን።'},
            {id: 3, value: 'ቻው ኢለዮ።'},
            {id: 4, value: 'ችኮላታ ይፈቱ።'}
        ],
        "advanced": [
            {id: 1, value: 'ኣነ ቸዝ ተጻዊተ።'},
            {id: 2, value: 'ቺታ እንስሳ ዘገቤት እዩ።'},
            {id: 3, value: 'ኣርያም ቻው ኢላትኒ።'},
            {id: 4, value: 'ቡዙሕ ችኮላታ ምብላዕ ጽቡቅ ኣይኮነን።'}
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 12,
    },
    "n": {
        "letter":[
            {id: 1,lable:'\u1290', value: 'ne', geez: 'ነ', type: ItemTypes.HADE },
            {id: 2,lable:'\u1291', value: 'nu', geez: 'ኑ', type: ItemTypes.KILITE },
            {id: 3,lable:'\u1292', value: 'ni', geez: 'ኒ', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u1293', value: 'na', geez: 'ና', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u1294', value: 'nie',geez: 'ኔ', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u1295', value: 'n',  geez: 'ን', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u1296', value: 'no', geez: 'ኖ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-n',
        "title": 'ne',
        "path":'/reading/ne',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel ve ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ነብሪ'},
            {id: 2, value: 'ኑዋ' },
            {id: 3, value: 'ኒሞ'},
            {id: 4, value: 'ናተይ'},
            {id: 5, value: 'ንብዓት'},
            {id: 6, value: 'ኖርወይ'},
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ነብሪ ርእየ።'},
            {id: 2, value: 'ኑዋ በኽዩ።'},
            {id: 3, value: 'ኒሞ ዘሊሉ።'},
            {id: 4, value: 'ናተይ ቦርሳ።'},
            {id: 5, value: 'ንብዓት ነቢዓ።'},
            {id: 6, value: 'ኖርወይ ይነብር።'},
        ],
        "advanced": [
            {id: 1, value: 'ኣነ ነብሪ ርእየ ሃዲመ።'},
            {id: 2, value: 'ኑዋ ንፉዕ ተመሃራይ እዩ።'},
            {id: 3, value: 'ኒሞ ኣራንሾኒ ዓሳ እዩ።'},
            {id: 4, value: 'ናተይ ቦርሳይ ሰመያዊ ሕብሪ ኣለዎ።'},
            {id: 5, value: 'ክንበኪ ኮለና ንብዓት ነውጽእ።'},
            {id: 6, value: 'ኣነ ኖርወይ ተወሊደ።'}
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 13,
    },
    "N": {
        "letter":[
            {id: 1, lable:'\u1298', value: 'Ne', geez: 'ኘ', type: ItemTypes.HADE },
            {id: 2, lable:'\u1299', value: 'Nu', geez: 'ኙ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u129A', value: 'Ni', geez: 'ኚ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u129B', value: 'Na', geez: 'ኛ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u129C', value: 'Nie',geez: 'ኜ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u129D', value: 'N',  geez: 'ኝ', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u129E', value: 'No', geez: 'ኞ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-N',
        "title": 'Ne',
        "path": '/reading/Ne',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Ne ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ትግርኛ'},
            {id: 2, value: 'ሕኛ' },
            {id: 3, value: 'ዳኛ'},
            {id: 4, value: 'ኛው'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ትግርኛ ይመሃር ።'},
            {id: 2, value: 'ሕኛ ገይራ።'},
            {id: 3, value: 'ዳኛ ፈራዲ እዩ።'},
            {id: 4, value: 'ድሙ ኛው ኢላ።'}
        ],
        "advanced": [
            {id: 1, value: 'ቀዳም ቀዳም ትግርኛ ንመሃር።'},
            {id: 2, value: 'ማማ ኣብ ኣውደኣመት ሕኛ ትገብር።'},
            {id: 3, value: 'ዳኛ ኣብ ቤት ፍርዲ ይሰርሕ።'},
            {id: 4, value: 'ድሙ ጠምያ ኛው ኢላ።'}
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 14,
    },
    "a": {
        "letter":[
            {id: 1,lable:'\u12A0', value: 'e', geez: 'አ', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', value: 'u', geez: 'ኡ', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', value: 'i', geez: 'ኢ', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', value: 'a', geez: 'ኣ', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', value: 'ie',geez: 'ኤ', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', value: 'A', geez: 'እ',  type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', value: 'o', geez: 'ኦ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-a',
        "title": 'a',
        "path": '/reading/a',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel a ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ኡጋንዳ'},
            {id: 2, value: 'ኣስመራ' },
            {id: 3, value: 'ኢራብ'},
            {id: 4, value: 'ኣንበሳ'},
            {id: 5, value: 'ኤርትራ'},
            {id: 6, value: 'እግሪ'},
            {id: 7, value: 'ኦም'}
        ],
        "easy_row_2": [
            {id: 1, value: ''},
            {id: 2, value: '' },
            {id: 3, value: ''},
            {id: 4, value: ''},
            {id: 5, value: ''},
            {id: 6, value: ''},
            {id: 7, value: ''}
        ],
        "medium": [
            {id: 1, value: 'ኡጋንዳ ከይደ።'},
            {id: 2, value: 'ኣስመራ ከተማ።'},
            {id: 3, value: 'ኢራብ ዘሊላ።'},
            {id: 4, value: 'ኣንበሳ ነዲሩ።'},
            {id: 5, value: 'ኤርትራ ሃገርና።'},
            {id: 6, value: 'እግሪ ጉዕዞ።'},
            {id: 7, value: 'ኦም ሓኪረ።'}
        ],
        "advanced": [
            {id: 1, value: 'ኡጋንዳ ኣብ ኣፍሪቃ ትርከብ።'},
            {id: 2, value: 'ኣስመራ ከይደ በለስ ብሊዐ።l'},
            {id: 3, value: 'ኢራብ እንስሳ ዘገዳም እያ።'},
            {id: 4, value: 'ኣንበሳ ንጉስ በረኻ እዩ።'},
            {id: 5, value: 'ኤርትራ ትሽዓተ ብሄራት ኣለዋ።'},
            {id: 6, value: 'እግሪ ጉዕዞ ከይደ ደኪመ።'},
            {id: 7, value: 'ካብ ኦም ወዲቀ ተሃሪመ።'}
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 15,
    },
    "A": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-A',
        "title": 'A',
        "path": '/reading/A',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel A ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "i": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-i',
        "title": 'i',
        "path": '/reading/i',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel i ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "I": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-I',
        "title": 'I',
        "path":'/reading/I',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel I ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "e": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-e',
        "title": 'e',
        "path": '/reading/e',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel e ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "E": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-E',
        "title": 'E',
        "path": '/reading/E',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel E ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "u": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-u',
        "title": 'u',
        "path": '/reading/u',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel u ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "U": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-U',
        "title": 'U',
        "path": '/reading/U',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel U ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "o": {
        "letter":[
            {id: 1,lable:'\u12A0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12A1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12A2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12A3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12A4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12A5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12A6', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-o',
        "title": 'o',
        "path": '/reading/o',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel o ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "k": {
        "letter":[
            {id: 1, lable:'\u12A8', value: 'ke', geez: 'ከ', type: ItemTypes.HADE },
            {id: 2, lable:'\u12A9', value: 'ku', geez: 'ኩ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u12AA', value: 'ki', geez: 'ኪ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12AB', value: 'ka', geez: 'ካ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12AC', value: 'kie',geez: 'ኬ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12AD', value: 'k',  geez: 'ክ', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12AE', value: 'ko', geez: 'ኮ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-k',
        "title": 'ke',
        "path": '/reading/k',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel ke ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ከልቢ'},
            {id: 2, value: 'ኩባያ' },
            {id: 3, value: 'ኪሎ'},
            {id: 4, value: 'ካራ'},
            {id: 5, value: 'ክራር'},
            {id: 6, value: 'ኮኾብ'},
        ],
        "easy_row_2": [
            {id: 1, value: 'ከልቢ ጎይኒ።'},
            {id: 2, value: 'ኩባያ ገዚአ።' },
            {id: 3, value: 'ኪሎ ባናና።'},
            {id: 4, value: 'ካራ ገዚአ።'},
            {id: 5, value: 'ክራር ይጻወት።'},
            {id: 6, value: 'ኮኾብ ርእየ።'}
        ],
        "medium": [
            {id: 1, value: 'ከልቢ እንስሳ ዘቤት እዩ።'},
            {id: 2, value: 'ኩባያ ገይረ ሻሂ ሰትየ።'},
            {id: 3, value: 'ኪሎ ሚዛን እዩ።'},
            {id: 4, value: 'ካራ ኢደይ ሓሪዱኒ።'},
            {id: 5, value: 'ባባ ክራር ህያብ ገዚኡለይ።'},
            {id: 6, value: 'ኮኾብ ኣብ ጸልማት ርእየ።'}
        ],
        "advanced": [
            {id: 1, value: ''},
            {id: 2, value: ''},
            {id: 3, value: ''},
            {id: 4, value: ''},
            {id: 5, value: ''}
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 16,
    },
    "K": {
        "letter":[
            {id: 1, lable:'\u12B8', value: 'Ke', geez: 'ኸ', type: ItemTypes.HADE },
            {id: 2, lable:'\u12B9', value: 'Ku', geez: 'ኹ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u12BA', value: 'Ki', geez: 'ኺ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12BB', value: 'Ka', geez: 'ኻ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12BC', value: 'Kie',geez: 'ኼ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12BD', value: 'K',  geez: 'ኽ', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12BE', value: 'Ko', geez: 'ኾ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-K',
        "title": 'Ke',
        "path": '/reading/Ke',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Ke ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ሓኺኸ'},
            {id: 2, value: 'ተኺኹ' },
            {id: 3, value: 'ንስኻ'},
            {id: 4, value: 'ኮኾብ'},
            {id: 5, value: 'ሕንቲኽቲኽ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ርእሰይ ሓኺኸ ።'},
            {id: 2, value: 'ትኪ ተኺኹ ።' },
            {id: 3, value: 'ኾኾብ ርእየ ።'},
            {id: 4, value: 'ሕንቲኽቲኽ ተጻዊትና ።'}
        ],
        "advanced": [
            {id: 1, value: 'ኣነ ርእሰይ ሓኺኸ ።'},
            {id: 2, value: 'ብዙሕ ትኪ ተኺኹ ።'},
            {id: 3, value: 'ኣብ ሰማይ ኮኾብ ርእየ ።'},
            {id: 4, value: 'ምስ ኣቦይ ሕንቲኽቲኽ ተጻዊትና።'}
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 17,
    },
    "w": {
        "letter":[
            {id: 1, lable:'\u12C8', value: 'we', geez: 'ወ', type: ItemTypes.HADE },
            {id: 2, lable:'\u12C9', value: 'wu', geez: 'ዉ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u12CA', value: 'wi', geez: 'ዊ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12CB', value: 'wa', geez: 'ዋ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12CC', value: 'wie',geez: 'ዌ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12CD', value: 'w',  geez: 'ው', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12CE', value: 'wo', geez: 'ዎ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-w',
        "title": 'we',
        "path": '/reading/we',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel we ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ወይኒ'},
            {id: 2, value: 'ዉሕጅ' },
            {id: 3, value: 'ዊንታ'},
            {id: 4, value: 'ዋዕሮ'},
            {id: 5, value: 'ልዌት'},
            {id: 6, value: 'ዎው ኢላ።'}

        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ወይኒ በሊዓ።'},
            {id: 2, value: 'ውሕጅ መሊኡ።' },
            {id: 3, value: 'ዊንታ ተኸዲና።'},
            {id: 4, value: 'ዋዕሮ ደቂሳ።'},
            {id: 5, value: 'ልዌት ተከዲነ።'},
            {id: 6, value: 'ዎው ኢላ።'}


        ],
        "advanced": [
            {id: 1, value: 'ዝፈትዎ ዓይነት ፉሩታ ወይኒ እዩ።'},
            {id: 2, value: 'ውሕጅ ኮይኑ ትምህርቲ ኣይከድኩን።'},
            {id: 3, value: 'ዊንታ ሓድሽ ልዌት ተኸዲና።'},
            {id: 4, value: 'ዋዕሮ ኣደ ኣንበሳ እያ።'},
            {id: 5, value: 'እታ ኣምቡላንስ "ዎው ዎው" ኢላ።'}
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 18,
    },
    "W": {
        "letter":[
            {id: 1, lable:'\u12C8', type: ItemTypes.HADE },
            {id: 2, lable:'\u12C9', type: ItemTypes.KILITE },
            {id: 3, lable:'\u12CA', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12CB', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12CC', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12CD', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12CE', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-W',
        "title": 'W',
        "path": '/reading/We',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel We ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "O": {
        "letter":[
            {id: 1,lable:'\u12D0', value: 'Oe', geez: 'ዐ', type: ItemTypes.HADE },
            {id: 2,lable:'\u12D1', value: 'Ou', geez: 'ዑ', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12D2', value: 'Oi', geez: 'ዒ', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12D3', value: 'Oa', geez: 'ዓ', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12D4', value: 'Oie',geez: 'ዔ', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12D5', value: 'O',  geez: 'ዕ', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12D6', value: 'Oo', geez: 'ዖ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-O',
        "title": 'Oe',
        "path": '/reading/Oe',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Oe ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ልግዐ'},
            {id: 2, value: 'ዑንቂ' },
            {id: 3, value: 'ዒላ'},
            {id: 4, value: 'ዓራት'},
            {id: 5, value: 'ዕየት'},
            {id: 6, value: 'ዖና'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ልግዐ ጥዒመ።'},
            {id: 2, value: 'ዑንቂ ኣለኒ።' },
            {id: 3, value: 'ዒላ መሊኡ።'},
            {id: 4, value: 'ዓራት ሰሪሐ።'},
            {id: 5, value: 'ዕየት ሓቂፈ።'},
            {id: 6, value: 'ዖና ርእየ።'}
        ],
        "advanced": [
            {id: 1, value: 'ላምና ወሊዳ ልግዐ ጥዒመዮ።'},
            {id: 2, value: 'ጽብቅቲ ዑንቂ ኣላትኒ።'},
            {id: 3, value: 'ዒላ ማይ መሊኡ።'},
            {id: 4, value: 'ደቂሰ ከለኩ ዓራተይ ተሰይሩ።'},
            {id: 5, value: 'እንዳ ኣባሓጎይ ዖና ርእየዮ።'},
            {id: 6, value: 'ኣንስተይቲ በጊዕና ዕየት ወሊዳ።'}

        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 19,
    },
    "z": {
        "letter":[
            {id: 1, lable:'\u12D8', value: 'ze', geez: 'ዘ', type: ItemTypes.HADE },
            {id: 2, lable:'\u12D9', value: 'zu', geez: 'ዙ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u12DA', value: 'zi', geez: 'ዚ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12DB', value: 'za', geez: 'ዛ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12DC', value: 'zie',geez: 'ዜ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12DD', value: 'z',  geez: 'ዝ', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12DE', value: 'zo', geez: 'ዞ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-z',
        "title": 'ze',
        "path": '/reading/ze',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel ze ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ዘቢብ'},
            {id: 2, value: 'ዙረት' },
            {id: 3, value: 'ዚንጎ'},
            {id: 4, value: 'ዛባ'},
            {id: 5, value: 'ዜና'},
            {id: 6, value: 'ዝናብ'},
            {id: 7, value: 'ጉዕዞ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ዘቢብ በሊዐ ።'},
            {id: 2, value: 'ንዙረት ከይድና ።' },
            {id: 3, value: 'ዚንጎ ኣሎ ።'},
            {id: 4, value: 'ዛባ ሒዘ ።'},
            {id: 5, value: 'ዜና ሰሚዐ ።'},
            {id: 6, value: 'ዝናብ ዘኒቡ ።'},
            {id: 7, value: 'ጉዕዞ ንፍቶ ።'}
        ],
        "advanced": [
            {id: 1, value: 'ጥዑም ዘቢብ በሊዐ ።'},
            {id: 2, value: 'ኩላትና ንዙረት ኬድና ።'},
            {id: 3, value: 'ኣብ ላዕሊ ዚንጎ ኣሎ ።'},
            {id: 4, value: 'ዛባ ሒዘ ሰሪሐ ።'},
            {id: 5, value: 'ካብ ሬድዮ ዜና ሰሚዐ ።'},
            {id: 6, value: 'ብዙሕ ዝናብ ዘኒቡ ።'},
            {id: 7, value: 'እግሪ ጉዕዞ ንፍቶ ።'}

        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 20,
    },
    "y": {
        "letter":[
            {id: 1, lable:'\u12E8', value: 'ye', geez: 'የ', type: ItemTypes.HADE },
            {id: 2, lable:'\u12E9', value: 'yu', geez: 'ዩ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u12EA', value: 'yi', geez: 'ዪ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12EB', value: 'ya', geez: 'ያ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12EC', value: 'yie',geez: 'ዬ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12ED', value: 'y',  geez: 'ይ', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12EE', value: 'yo', geez: 'ዮ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-y',
        "title": 'ye',
        "path": '/reading/ye',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel ye ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ደየበ'},
            {id: 2, value: 'ዩቱብ' },
            {id: 3, value: 'ማልያ'},
            {id: 4, value: 'ጽውጹዋይ'},
            {id: 5, value: 'ዮውሃንስ'}

        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ኣስካላ ደየበ።'},
            {id: 2, value: 'ዪቱብ ተጠቂመ።' },
            {id: 3, value: 'ማልያ ተከዲነ።'},
            {id: 4, value: 'ጽውጹዋይ ይፈቱ።'},
            {id: 5, value: 'ዮውሃንስ ኣጠሚቑ።'}

        ],
        "advanced": [
            {id: 1, value: 'ንሱ ጎቦ ደየበ።'},
            {id: 2, value: 'አብ ዩቱብ መዝሙር ሰሚዐ።'},
            {id: 3, value: 'ሃሩር ክከውን ከሎ ማልያ ይክደን።'},
            {id: 4, value: 'ኣደይ ጽውጽዋይ ኣንቢባትለይ ክድቅስ ከለኩ።'},
            {id: 1, value: 'ዮውሃንስ ንየሱስ አጠሚቅዎ።'},

        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 21,
    },
    "Y": {
        "letter":[
            {id: 1, lable:'\u12E8', type: ItemTypes.HADE },
            {id: 2, lable:'\u12E9', type: ItemTypes.KILITE },
            {id: 3, lable:'\u12EA', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u12EB', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u12EC', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u12ED', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u12EE', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-Y',
        "title": 'Ye',
        "path": '/reading/Ye',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Ye ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "d": {
        "letter":[
            {id: 1,lable:'\u12F0', value: 'de', geez: 'ደ', type: ItemTypes.HADE },
            {id: 2,lable:'\u12F1', value: 'du', geez: 'ዱ', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12F2', value: 'di', geez: 'ዲ', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12F3', value: 'da', geez: 'ዳ', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12F4', value: 'die',geez: 'ዴ', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12F5', value: 'd',  geez: 'ድ', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12F6', value: 'do', geez: 'ዶ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-d',
        "title": 'de',
        "path": '/reading/de',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel de ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ደርሆ'},
            {id: 2, value: 'ዱሙ' },
            {id: 3, value: 'ዲያቆን'},
            {id: 4, value: 'ቅዳሴ'},
            {id: 5, value: 'ባንዴራ'},
            {id: 6, value: 'ድቢ'},
            {id: 7, value: 'ኣቨካዶ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ደርሆ ወሊዳ።'},
            {id: 2, value: 'ዱሙ ኣለና።'},
            {id: 3, value: 'ዲያቆን ቀዲሱ።'},
            {id: 4, value: 'ቅዳሴ ኣሎ።'},
            {id: 5, value: 'ባንዴራ አንበልቢለ።'},
            {id: 6, value: 'ድቢ ደቂሱ።'}
        ],
        "advanced": [
            {id: 1, value: 'ደርሆ እንቋቅሖ ወሊዳ።'},
            {id: 2, value: 'ዱሙ እንስሳ ዘቤት እያ።'},
            {id: 3, value: 'ዲያቆን ንኣቦ ቀሺ ሓጊዝዎ።'},
            {id: 4, value: 'ሰንበት ቅዳሴ ኣሎ።'},
            {id: 5, value: 'ባንዴራ ኤሪትራ ኣርባዕተ ሕብራ።'},
            {id: 6, value: 'ድቢ ደቂሱ ርእየ።'},
            {id: 7, value: 'አቩካዶ ምስ ባኒ በሊዐ።'},
        ],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 22,
    },
    "D": {
        "letter":[
            {id: 1,lable:'\u12F0', type: ItemTypes.HADE },
            {id: 2,lable:'\u12F1', type: ItemTypes.KILITE },
            {id: 3,lable:'\u12F2', type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12F3', type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12F4', type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12F5', type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12F6', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-D',
        "title": 'D',
        "path": '/reading/D',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel De ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": false,
    },
    "j": {
        "letter":[
            {id: 1, lable:'\u1300', value: 'je', geez: 'ጀ', type: ItemTypes.HADE },
            {id: 2, lable:'\u1301', value: 'ju', geez: 'ጁ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u1302', value: 'ji', geez: 'ጂ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u1303', value: 'ja', geez: 'ጃ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u1304', value: 'jie',geez: 'ጄ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u1305', value: 'j',  geez: 'ጅ', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u1306', value: 'jo', geez: 'ጆ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-j',
        "title": 'je',
        "path": '/reading/je',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel je ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ጀበና'},
            {id: 2, value: 'ጁባ' },
            {id: 3, value: 'ሕጂ'},
            {id: 4, value: 'ጃከት'},
            {id: 5, value: 'ጅቡቲ'},
            {id: 6, value: 'ፋርማጆ'},
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ጀበና ኣሎና ።'},
            {id: 2, value: 'ጁባይ ተቀዲዱ።' },
            {id: 3, value: 'ሕጂ በሊዐ።'},
            {id: 4, value: 'ጃኬት ተኸዲነ።'},
            {id: 5, value: 'ጅቡቲ ገይሸ።'},
            {id: 6, value: 'ፋርማጆ ገዚኣ።'}
        ],
        "advanced": [
            {id: 1, value: 'ጀበና መፍልሒ ቡን እዩ።'},
            {id: 2, value: 'ጁባይ ተቀዲዱ መጻወትየይ ወዲቁ ።'},
            {id: 3, value: 'ሕጂ ቁርሲ በሊዐ።'},
            {id: 4, value: 'ቁሪ ኮይንኒ ጃኬት ተኸዲነ።'},
            {id: 5, value: 'ጅቡቲ ጎሮቤት ኤርትራ ሃገርና እያ።'},
            {id: 6, value: 'ፋርማጆ ካብ ዱኳን ገዚአ።'}

        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 23,
    },
    "J": {
        "letter":[
            {id: 1, lable:'\u1300', type: ItemTypes.HADE },
            {id: 2, lable:'\u1301', type: ItemTypes.KILITE },
            {id: 3, lable:'\u1302', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u1303', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u1304', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u1305', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u1306', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-J',
        "title": 'Je',
        "path": '/reading/Je',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Je ms abnetat',
        "easy_row_1": [
        ],
        "easy_row_2": [
        ],
        "medium": [
        ],
        "advanced": [
        ],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "g": {
        "letter":[
            {id: 1, lable:'\u1308', value: 'ge',geez: 'ገ',type: ItemTypes.HADE },
            {id: 2, lable:'\u1309', value: 'gu',geez: 'ጉ',type: ItemTypes.KILITE },
            {id: 3, lable:'\u130A', value: 'gi',geez: 'ጊ',type: ItemTypes.SELESTE},
            {id: 4, lable:'\u130B', value: 'ga',geez: 'ጋ',type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u130C', value: 'gie',geez: 'ጌ',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u130D', value: 'g', geez: 'ግ',type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u130E', value: 'go',geez: 'ጎ',type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-g',
        "title": 'ge',
        "path": '/reading/ge',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel ge ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ገመል'},
            {id: 2, value: 'ጉንጋ' },
            {id: 3, value: 'ጊባቦ'},
            {id: 4, value: 'ጋንታ'},
            {id: 5, value: 'ጌጋ'},
            {id: 6, value: 'ግንባር'},
            {id: 7, value: 'ጎብየ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ነዋሕ ገመል።'},
            {id: 2, value: 'ጉንጓ ነፊሩ።' },
            {id: 3, value: 'ጊባቦ መቀመጢ እንጀራ።'},
            {id: 4, value: 'ጋንታ ኩዕሶ እግሪ።'},
            {id: 5, value: 'ጌጋ ገይረ።'},
            {id: 6, value: 'ብግንባረይ ወዲቀ።'},
            {id: 7, value: 'ጎብየ ደኺሙ።'}
        ],
        "advanced": [
            {id: 1, value: 'ገመል ነዊሕ ክሳድ ኣለዋ።'},
            {id: 2, value: 'ጉንጋ ጥዑም ድምጺ ኣለዎ።'},
            {id: 3, value: 'ጊባቦ ከቢብ ቅርጺ ኣለዎ።'},
            {id: 4, value: 'ኣነ ጋንታ ማንቸስተር ይድግፍ።'},
            {id: 5, value: 'ንሱ ጌጋ ገይሩ ይቅሬታ ሓቲቱ።'},
            {id: 6, value: 'ብግንባረይ ወዲቀ ደም ወጺኤ።'},
            {id: 7, value: 'ጎብየ ደኺሙ ኣብ መንገዲ ደቂሱ።'}

        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 24,
    },
    "G": {
        "letter":[
            {id: 1, lable:'\u1308', value: 'Ge', type: ItemTypes.HADE },
            {id: 2, lable:'\u1309', value: 'Gu', type: ItemTypes.KILITE },
            {id: 3, lable:'\u130A', value: 'Gi', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u130B', value: 'Ga', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u130C', value: 'Gie',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u130D', value: 'G',  type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u130E', value: 'Go', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-G',
        "title": 'G',
        "path": '/reading/G',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Ge ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "T": {
        "letter":[
            {id: 1, lable:'\u1320',value: 'Te',geez: 'ጠ', type: ItemTypes.HADE },
            {id: 2, lable:'\u1321',value: 'Tu',geez: 'ጡ', type: ItemTypes.KILITE },
            {id: 3, lable:'\u1322',value: 'Ti',geez: 'ጢ', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u1323',value: 'Ta',geez: 'ጣ', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u1324',value: 'Tie', geez: 'ጤ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u1325',value: 'T', geez: 'ጥ', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u1326',value: 'To', geez: 'ጦ', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-T',
        "title": 'Te',
        "path": '/reading/Te',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Te ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ጠጠም'},
            {id: 2, value: 'ጡጥ' },
            {id: 3, value: 'ጢል'},
            {id: 4, value: 'ጥጦ'},
            {id: 5, value: 'ጦቦበ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ጠጠም በሊዓ።'},
            {id: 2, value: 'ጡጥ ኣለና።'},
            {id: 3, value: 'ጤል ርእየ።'},
            {id: 4, value: 'ጥጦ ገዚኣ።'},
            {id: 5, value: 'ቆልዓ ጦበበ።'},
        ],
        "advanced": [
            {id: 1, value: 'ጠጠም ኣጣል ዝበልዐኦ ኣቚጽልቲ እዩ።'},
            {id: 2, value: 'ጡጥ ጻዕዳ እዩ።'},
            {id: 3, value: 'ጤል እንስሳ ዘቤት እያ።'},
            {id: 4, value: 'ማማ ንፍሊሞን ጥጦ ገዚኣትሉ።'},
            {id: 5, value: 'ውልድ ምስ በለ ናይ ኣዲኡ ጡብ ጦበበ።'}
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
    },
    "C": {
        "letter":[
            {id: 1, lable:'\u1328', value: 'Ce',geez: 'ጨ',type: ItemTypes.HADE },
            {id: 2, lable:'\u1329', value: 'Cu',geez: 'ጩ',type: ItemTypes.KILITE },
            {id: 3, lable:'\u132A', value: 'Ci',geez: 'ጪ',type: ItemTypes.SELESTE},
            {id: 4, lable:'\u132B', value: 'Ca',geez: 'ጫ',type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u132C', value: 'Cie',geez: 'ጬ',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u132D', value: 'C', geez: 'ጭ',type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u132E', value: 'Co',geez: 'ጮ',type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-C',
        "title": 'Ce',
        "path": '/reading/Ce',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Ce ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ጨቓዊት'},
            {id: 2, value: 'ጩቕ-ጩቕ' },
            {id: 3, value: 'ጪርር'},
            {id: 4, value: 'ጫማ'},
            {id: 5, value: 'ጬጬጬ'},
            {id: 6, value: 'ጭሕሚ'},
            {id: 7, value: 'ጭቃ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ጨቓቂት ርእየ።'},
            {id: 2, value: 'ጩቕ-ጩቕ ኢሎም።' },
            {id: 3, value: 'ጪርር ኢላ።'},
            {id: 4, value: 'ጫማ ገዚኣትለይ።'},
            {id: 5, value: 'ጭሕሚ የብለይን።'},
            {id: 6, value: 'ጭቃ በሊዐ።'},
            {id: 7, value: 'ፍንጮ ነይርዋ።'}

        ],
        "advanced": [
            {id: 1, value: 'ጨቓዊት እንተጠምዮም ጩቕ-ጩቕ ይብሉ።'},
            {id: 2, value: 'ተሌፎን ናይ ገዛና ጪርር ትብል።'},
            {id: 3, value: 'ማማ ትማሊ ጫማ ገዚኣትለይ።'},
            {id: 4, value: 'ኣነ ህጻን ስለዝኾንኩ ጭሕሚ የብለይን።'},
            {id: 5, value: 'ስንና ፍንጮ ንኸይወጾ ክንጥንቀቕ ኣለና።'},
            {id: 6, value: 'ኣብ ጭቃ ጥር ጥር ኢለ።'}

        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 25,
    },
    "p": {
        "letter":[
            {id: 1, lable:'\u1350', value: 'pe', geez: 'ፐ',type: ItemTypes.HADE },
            {id: 2, lable:'\u1351', value: 'pu', geez: 'ፑ',type: ItemTypes.KILITE },
            {id: 3, lable:'\u1352', value: 'pi', geez: 'ፒ',type: ItemTypes.SELESTE},
            {id: 4, lable:'\u1353', value: 'pa', geez: 'ፓ',type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u1354', value: 'pie',geez: 'ፔ', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u1355', value: 'p',  geez: 'ፕ',type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u1356', value: 'po', geez: 'ፖ',type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-p',
        "title": 'pe',
        "path": '/reading/pe',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel pe ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ፓይሎት'},
            {id: 2, value: 'ፖስታ' },
            {id: 3, value: 'ፓፓጋሎ'},
            {id: 4, value: 'ፖሊስ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ፓይሎት ርእየ።'},
            {id: 2, value: 'ፖስታ መጺዩኒ።' },
            {id: 3, value: 'ፓፓጋሎ ይዛረብ።'},
            {id: 4, value: 'ፖሊስ ሒዝዋ።'},
            {id: 5, value: 'ፓስታ ተመሲሐ።'}

        ],
        "advanced": [
            {id: 1, value: 'ፓይሎት ነፋሪት ይዝውር።'},
            {id: 2, value: 'ፓፓጋሎ ዘረባ ይደግም።'},
            {id: 3, value: 'ፖሊስ ንሰራቂ ሒዙ ኣሲርዎ።'},
            {id: 4, value: 'ትማሊ ፓስታ ተመሲሐ።'}

        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 26,
    },
    "x": {
        "letter":[
            {id: 1, lable:'\u1338', value: 'xe', geez: 'ጸ',type: ItemTypes.HADE },
            {id: 2, lable:'\u1339', value: 'xu', geez: 'ጹ',type: ItemTypes.KILITE },
            {id: 3, lable:'\u133A', value: 'xi', geez: 'ጺ',type: ItemTypes.SELESTE},
            {id: 4, lable:'\u133B', value: 'xa', geez: 'ጻ',type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u133C', value: 'xie',geez: 'ጼ',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u133D', value: 'x',  geez: 'ጽ',type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u133E', value: 'xo', geez: 'ጾ',type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-x',
        "title": 'xe',
        "path": '/reading/xe',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel xe ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ጸባ'},
            {id: 2, value: 'ጹሩይ' },
            {id: 3, value: 'መጺጽ'},
            {id: 4, value: 'ጻጸ'},
            {id: 5, value: 'ጽላል'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ጸባ ሰትየ።'},
            {id: 2, value: 'ጹሩይ ክዳን።'},
            {id: 3, value: 'መጺጽ እዩ።'},
            {id: 4, value: 'ጻጸ ርእየ።'},
            {id: 5, value: 'ጽላል ገዚአ።'}
        ],
        "advanced": [
            {id: 1, value: 'ኣብ ዓዲ ምስ ከድኩ ዓባየይ ጸባ ሓሊባትለይ።'},
            {id: 2, value: 'ማይ ጽሩይ ሰትየ።'},
            {id: 3, value: 'ጻጸ ጻዕራም እንስሳ እዩ።'},
            {id: 4, value: 'ማይ ምስ ሃረመ ጽላል ሒዘ።'}
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 27,
    },
    "X": {
        "letter":[
            {id: 1, lable:'\u1338', type: ItemTypes.HADE },
            {id: 2, lable:'\u1339', type: ItemTypes.KILITE },
            {id: 3, lable:'\u133A', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u133B', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u133C', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u133D', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u133E', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-X',
        "title": 'X',
        "path": '/reading/X',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Xe ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "f": {
        "letter":[
            {id: 1, lable:'\u1348', value: 'fe',geez: 'ፈ',type: ItemTypes.HADE },
            {id: 2, lable:'\u1349', value: 'fu',geez: 'ፉ',type: ItemTypes.KILITE },
            {id: 3, lable:'\u134A', value: 'fi',geez: 'ፊ',type: ItemTypes.SELESTE},
            {id: 4, lable:'\u134B', value: 'fa',geez: 'ፋ',type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u134C', value: 'fie', geez: 'ፌ',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u134D', value: 'f', geez: 'ፍ',type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u134E', value: 'fo',geez: 'ፎ',type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-f',
        "title": 'fe',
        "path": '/reading/fe',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel fe ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ፈረስ'},
            {id: 2, value: 'ፉል' },
            {id: 3, value: 'ፊልም'},
            {id: 4, value: 'ፋኑስ'},
            {id: 5, value: 'ፌኮ'},
            {id: 6, value: 'ፍዮሪ'},
            {id: 7, value: 'ፎኪስ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ፉል ኣይፈቱን’ዩ።'},
            {id: 2, value: 'ፊልም ርእየ።'},
            {id: 3, value: 'ፋኑስ ተወሊዑ።'},
            {id: 4, value: 'ፌኮ ሂባትኒ።'},
            {id: 5, value: 'ፍዮሪ ገዚኡ።'},
            {id: 6, value: 'ፎኪስ እዩ ።'}
        ],
        "advanced": [
            {id: 1, value: 'ሓብተይ ፈረስ ተወጢሓ።'},
            {id: 2, value: 'ሓወበይ ናይ ፉል ኣለርጂ ኣለዎ።'},
            {id: 3, value: 'ምስ ኣኮይ ፊልም ርእየ።'},
            {id: 4, value: 'ኣባሓጎይን ዓባየይን ፋኑስ ኣለዎም።'},
            {id: 5, value: 'ሓትነይ ፌኮ ሂባትኒ።'},
            {id: 6, value: 'ኣቦይ ን ኣደይ ፍዮሪ ገዚኡላ።'},
            {id: 7, value: 'ንእሽቶይ ሓወይ ፎኪስ እዩ።'},
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 28,
    },
    "F": {
        "letter":[
            {id: 1, lable:'\u1348', type: ItemTypes.HADE },
            {id: 2, lable:'\u1349', type: ItemTypes.KILITE },
            {id: 3, lable:'\u134A', type: ItemTypes.SELESTE},
            {id: 4, lable:'\u134B', type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u134C', type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u134D', type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u134E', type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-F',
        "title": 'F',
        "path": '/reading/F',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Fe ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.SECOND,
        "main": false,
    },
    "P": {
        "letter":[
            {id: 1, lable:'\u1330', value: 'Pe',geez: 'ጰ',type: ItemTypes.HADE },
            {id: 2, lable:'\u1331', value: 'Pu',geez: 'ጱ',type: ItemTypes.KILITE },
            {id: 3, lable:'\u1332', value: 'Pi',geez: 'ጲ',type: ItemTypes.SELESTE},
            {id: 4, lable:'\u1333', value: 'Pa',geez: 'ጳ',type: ItemTypes.ARBAETE},
            {id: 5, lable:'\u1334', value: 'Pie', geez: 'ጴ',type: ItemTypes.HAMUSHTE},
            {id: 6, lable:'\u1335', value: 'P', geez: 'ጵ',type: ItemTypes.SHUDUSHTE},
            {id: 7, lable:'\u1336', value: 'Po',geez: 'ጶ',type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-P',
        "title": 'Pe',
        "path": '/reading/Pe',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Pe ms abnetat',
        "easy_row_1": [
            {id: 1, value: 'ጲላጦስ'},
            {id: 2, value: 'ጳጳስ' },
            {id: 3, value: 'ጠረጴዛ'},
            {id: 4, value: 'ጳጉሜ'}
        ],
        "easy_row_2": [
        ],
        "medium": [
            {id: 1, value: 'ጴላጦስ ትሓጺቡ።'},
            {id: 2, value: 'ጳጳስ ቀዲሱ።'},
            {id: 3, value: 'ጳጉሜ ነይሩ።'},
            {id: 4, value: 'ሓመዳዊ ጠረጴዛ።'}
        ],
        "advanced": [
            {id: 1, value: 'ጴላጦስ ኢዱ ተሓጺቡ።'},
            {id: 2, value: 'ጳጳስ ይቅድስ ነይሩ።'},
            {id: 3, value: 'ኣብ ጠረጴዛ ኮይነ ስእሊ ስኢለ።'},
            {id: 4, value: 'ኣብ በዓል ጳጉመ ተሳቲፈ።'}
        ],
        "letterType": letterTypes.SECOND,
        "main": true,
        "mainIndex": 29,
    },
    "Z": {
        "letter":[
            {id: 1,lable:'\u12E0', value: 'Ze', geez: '',type: ItemTypes.HADE },
            {id: 2,lable:'\u12E1', value: 'Zu', geez: '',type: ItemTypes.KILITE },
            {id: 3,lable:'\u12E2', value: 'Zi', geez: '',type: ItemTypes.SELESTE},
            {id: 4,lable:'\u12E3', value: 'Za', geez: '',type: ItemTypes.ARBAETE},
            {id: 5,lable:'\u12E4', value: 'Zie',geez: '',type: ItemTypes.HAMUSHTE},
            {id: 6,lable:'\u12E5', value: 'Z',  geez: '',type: ItemTypes.SHUDUSHTE},
            {id: 7,lable:'\u12E6', value: 'Zo', geez: '',type: ItemTypes.SHEWATE},
        ],
        "key": 'alphabet-Z',
        "title": 'Ze',
        "path": '/reading/Ze',
        "img": cartoons.fidel,
        "posters": { first: cartoons.fidel, second: cartoons.fidel},
        "detail": 'nbab fidel Ze ms abnetat',
        "easy_row_1": [],
        "easy_row_2": [],
        "medium": [],
        "advanced": [],
        "letterType": letterTypes.FIRST,
        "main": true,
        "mainIndex": 30,
    },
};

export default tigrinyaAlphabets;

